import React, { useState } from 'react';
import { Form, Button, Input, Select, Checkbox, Row, Col } from 'antd';
import FileUpload from '../fileUpload';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	}
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 12,
			offset: 5
		}
	}
};

const AddUpdateSponsorForm = ({ selected, onCreate, onUpdate, onClose }) => {
	const [form] = Form.useForm();
	const [ image, setImage ] = useState(selected ? selected.image : null)

	const onTypeChange = value => {
    form.setFieldsValue({ type: value });
	};
	
	const onSubmit = (values) => {
		if (selected) {
			onUpdate({
				...selected,
				...values,
				image
			});
		} else {
			onCreate({
				...values,
				image
			});
		}
		onClose();
	};

	const onCompleteUpload = (url) => {
		setImage(url);
	}

	return (
		<Form
			form={form}
			{...formItemLayout}
			initialValues={{
				name: selected ? selected.name : '',
				url: selected ? selected.url : '',
				type: selected ? selected.type : 'medium',
				active: selected ? selected.active : false
			}}
			onFinish={onSubmit}
		>
			<Form.Item label="Name" name="name" rules={[ { required: true, message: 'Naam is verplicht' } ]}>
				<Input />
			</Form.Item>

			<Form.Item label="Url" name="url" rules={[ { required: true, message: 'Url is verplicht' } ]}>
				<Input />
			</Form.Item>

			<Form.Item
				label="Actief"
				name="active"
				valuePropName="checked"
				{...formItemLayout}
			>
				<Checkbox name="active" />
			</Form.Item>

			<Form.Item
				label="Type"
				name="type"
				{...formItemLayout}
			>
				<Select name="type" onChange={onTypeChange} style={{ width: 120 }}>
					<Select.Option value="small">Small</Select.Option>
					<Select.Option value="medium">Medium</Select.Option>
					<Select.Option value="large">Large</Select.Option>
				</Select>
			</Form.Item>

			<Row style={{ marginBottom: 16 }}>
				<Col xs={{ span: 24, offset: 0 }} sm={{ span: 19, offset: 5 }}>
					<FileUpload
						keepRatio={true}
						multiple={false}
						images={image ? [image] : []}
						onRemove={() => setImage(null)}
						onComplete={onCompleteUpload}
					/>
				</Col>
			</Row>

			<Form.Item {...tailLayout}>
				<Button htmlType="submit" type="primary">
					Opslaan
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AddUpdateSponsorForm;
