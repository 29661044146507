import React from "react"
import { Button, Space, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const SponsorsTable = ({ sponsors, onSelect, onRemove }) => {
	return (
		<Table
			columns={[
				{
					title: 'Afbeelding',
					key: 'image',
					dataIndex: 'image',
					render: (image) => (
						<img style={{ marginBottom: 8, marginRight: 8, width: 100 }} src={image} />
					)
				},
				{
					title: 'Naam',
					dataIndex: 'name',
					key: 'name'
				},
				{
					title: 'Type',
					key: 'type',
					dataIndex: 'type'
				},
				{
					title: 'Actief',
					key: 'active',
					dataIndex: 'active',
					render: (active) => (
						<Tag color={active ? 'green' : 'red'}>
							{ active ? 'Actief' : 'Inactief'}
						</Tag>
					)
				},
				{
					title: 'Action',
					key: 'action',
					className: 'column-align-right',
					render: (text, record) => {
						return (
							<Space>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<EditOutlined />}
									onClick={() => onSelect(record)}
								/>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<DeleteOutlined />}
									onClick={() => onRemove(record)}
									danger
								/>
							</Space>
						);
					}
				}
			]}
			rowKey='_id'
			dataSource={sponsors}
		/>
	);
}
export default SponsorsTable;
