import React, { useState, useEffect, useContext } from "react"

import AdminLayout from "../../layouts/admin"
import SEO from "../../components/seo"

import SponsorsTable from "../../components/admin/sponsors/sponsorTable"
import AddUpdateSponsorForm from "../../components/admin/sponsors/addUpdateSponsorForm"

import { SponsorContext } from "../../contexts/sponsorContext"

import Loader from "../../components/loader"

import { Modal, Button, message } from "antd"

import { ExclamationCircleOutlined } from "@ant-design/icons"

const AdminSponsorsPage = ({ location }) => {
  const {
    sponsors,
    sponsorsLoading,
    getSponsors,
    createSponsor: createItem,
    sponsorCreating,
    sponsorCreatingError,
    updateSponsor: updateItem,
    sponsorUpdating,
    sponsorUpdatingError,
    removeSponsor: removeItem,
    sponsorRemoving,
    sponsorRemovingError,
  } = useContext(SponsorContext)

  const [sponsor, setSponsor] = useState(null)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    getSponsors()
  }, [])

  const selectSponsor = sponsorToSelect => {
    setShowModal(sponsorToSelect != null)
    setSponsor(sponsorToSelect)
    setShowModal(sponsorToSelect != null)
  }

  const createSponsor = async sponsorToCreate => {
    const result = await createItem(sponsorToCreate)
    if (!result.errors) {
      setShowModal(false)
      selectSponsor(null)
    } else {
      message.error(result.errors.map(e => e.message).join(". "))
    }
  }

  const updateSponsor = async sponsorToUpdate => {
    const result = await updateItem(sponsorToUpdate)
    if (!result.errors) {
      setShowModal(false)
      selectSponsor(null)
    } else {
      message.error(result.errors.map(e => e.message).join(". "))
    }
  }

  const removeSponsor = async sponsorToDelete => {
    Modal.confirm({
      title: "Weet je het zeker",
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je '${sponsorToDelete.name}' wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
      onOk: async () => {
        const removed = await removeItem(sponsorToDelete)
        if (removed) {
          selectSponsor(null)
        } else {
          message.error("Er ging iets mis met het verwijderen.")
        }
      },
    })
  }
	
  return (
    <AdminLayout
      path={location.pathname}
      breadcrumbs={[{ link: "/admin/sponsors", title: "Sponsors" }]}
    >
      <SEO title="Admin sponsors" />

      <h1>
        Sponsors
        <Button
          type="default"
          onClick={() => setShowModal(true)}
          disabled={sponsor}
        >
          Sponsor toevoegen
        </Button>
      </h1>

      {sponsorsLoading ? (
        <Loader />
      ) : (
        <SponsorsTable
          sponsors={sponsors}
          onSelect={selectSponsor}
          onRemove={removeSponsor}
        />
      )}

      {showModal && (
        <Modal
          title="Sponsor toevoegen/wijzigen"
          visible={true}
          onCancel={() => selectSponsor(null)}
          footer={null}
          width={"90vw"}
        >
          <AddUpdateSponsorForm
            selected={sponsor}
            onCreate={createSponsor}
            onUpdate={updateSponsor}
            onClose={() => setShowModal(false)}
          />
        </Modal>
      )}
    </AdminLayout>
  )
}

export default AdminSponsorsPage
